<template>
  <div class="baseConsoleTablePage hasTopButton">
    <div class="pageTopButton">
      <el-button
        type="primary"
        size="small"
        icon="el-icon-plus"
        v-if="inspectButton('coi:ved:upv')"
        :disabled="inspectHandle('coi:ved:upv')"
        @click="uploadDialog = true"
        >{{ $t("uploadVideo") }}</el-button
      >
    </div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column prop="date" :label="$t('video')" min-width="80px">
          <template slot-scope="scope">
            <videoPlay
              ref="playVideo"
              :videoId="scope.row.aliyun_id"
              class="palyVideo"
              style="margin-bottom: 5px; margin-top: 2px"
            >
              <al-image
                :fit="'cover'"
                style="width: 100px; height: 68px"
                :src="scope.row.cover_url"
                class="coverImg"
              ></al-image>
              <div class="mark">
                <i
                  class="el-icon-video-play"
                  @click="playVideo(scope.row.aliyun_id)"
                ></i>
              </div>
            </videoPlay>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('videoName')" min-width="120px">
          <template slot-scope="scope">
            <div>
              <span
                :title="scope.row.name"
                class="rowName cursor"
                @click="gotoCompanySet(scope.row)"
                >{{ scope.row.name }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('playCountNum')" min-width="80px">
          <template slot-scope="scope">
            <div>
              <span style="font-size: 12px">{{ scope.row.play_count }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('createTime')" min-width="130px">
          <template slot-scope="scope">
            <div>
              <span style="font-size: 12px">{{
                scope.row.created_time | secondFormat
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('status')" min-width="90px">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">
              <span class="line" style="background: #c0c4cc"></span>
              <span style="line-height: 23px; font-size: 12px">{{
                $t("uploading")
              }}</span>
            </div>
            <div v-if="scope.row.status == 2">
              <div v-if="scope.row.audit_status == 1">
                <span class="line" style="background: #52c41a"></span>
                <span style="line-height: 23px; font-size: 12px">{{
                  $t("normal")
                }}</span>
              </div>
              <div v-if="scope.row.audit_status == 0">
                <span class="line" style="background: #409eff"></span>
                <span style="line-height: 23px; font-size: 12px">{{
                  $t("inReview")
                }}</span>
              </div>
              <div v-if="scope.row.audit_status == 2">
                <span class="line" style="background: #e6a23c"></span>
                <span style="line-height: 23px; font-size: 12px">{{
                  $t("aduitReject")
                }}</span>
                <el-popover
                  placement="top-start"
                  :title="LOCALE == 'en' ? 'Failure cause' : '失败原因'"
                  width="200"
                  trigger="click"
                  :content="scope.row.reason"
                >
                  <i
                    slot="reference"
                    class="el-icon-question"
                    style="color: #e6a23c; cursor: pointer"
                  ></i>
                </el-popover>
              </div>
            </div>
            <div v-if="scope.row.status == 1">
              <span class="line" style="background: #c0c4cc"></span>
              <span style="line-height: 23px; font-size: 12px">{{
                $t("transcoding")
              }}</span>
            </div>
            <div v-if="scope.row.status == 11">
              <span class="line" style="background: #f56c6c"></span>
              <span style="line-height: 23px; font-size: 12px">{{
                $t("uploadFail")
              }}</span>
            </div>
            <div v-if="scope.row.status == 12">
              <span class="line" style="background: #f56c6c"></span>
              <span style="line-height: 23px; font-size: 12px">{{
                $t("transcodeFail")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" :label="$t('handle')" min-width="50px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="inspectButton('coi:ved:del')"
              :disabled="inspectHandle('coi:ved:del')"
              @click="deletePicture(scope.row.id)"
              >{{ $t("delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="$t('uploadVideo')"
      :visible.sync="uploadDialog"
      width="670px"
      :destroy-on-close="true"
    >
      <uploadVideo
        @closeDialog="closeDialog"
        :options="{
          company_id: USER_INFO.company_id,
          type: 'video',
        }"
        @uploadSuccess="uploadSuccess"
      ></uploadVideo>
      <!-- $store.state.userInfo.company_id -->
    </el-dialog>
    <!-- 详情 -->
    <el-drawer
      :title="$t('Basicconfiguration')"
      :visible.sync="drawerShow"
      direction="rtl"
      size="550px"
    >
      <div>
        <videoDetail
          :video_id="videoId"
          @successChange="videoChange"
        ></videoDetail>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import uploadVideo from "~/baseComponents/uploadListDialog";
import videoDetail from "~bac/components/company/video";
import videoPlay from "@/baseComponents/videoPlay";
export default {
  components: { uploadVideo, videoDetail, videoPlay },
  data() {
    return {
      tableData: [],
      loading: false,
      total: 0,
      drawerSize: 1000,
      currentPage: 1,
      uploadDialog: false,
      drawerShow: false,
      videoId: null,
    };
  },
  methods: {
    async getList() {
      this.loading = true;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_getCompanyVideo",
          {
            limit: 10,
            start: this.currentPage - 1,
            company_id: this.USER_INFO.company_id,
          }
        );
        if (result.success) {
          this.tableData = result.data;
          this.total = result.total;
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    uploadSuccess() {
      this.uploadDialog = false;
      // this.RefreshPagesData();
      this.getList();
    },
    videoChange() {
      this.drawerShow = false;
      this.getList();
    },
    closeDialog() {
      this.uploadDialog = false;
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    playVideo(id) {
      if (id) this.$refs.playVideo.startPlay(id);
    },
    deletePicture(id) {
      this.$confirm(this.$t("confirmDelete"), this.$t("delete"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        closeOnClickModal: false,
        type: "warning",
      })
        .then(async () => {
          try {
            let params = {
              video_id: id,
            };
            let data = await this.$store.dispatch(
              "baseConsole/company_deleteVideo",
              params
            );
            if (data.success) {
              this.$delTip();
              this.getList();
            }
          } catch (e) {}
        })
        .catch(() => {});
    },
    gotoCompanySet(val) {
      this.drawerShow = true;
      this.videoId = val.id;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped lang="less">
.rowName {
  color: #0083f6;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.line {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
}
.palyVideo {
  width: 100px;
  height: 68px;
  position: relative;
  .coverImg {
    width: 100px;
    height: 68px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .mark {
    width: 100px;
    height: 68px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    text-align: center;
    cursor: pointer;
    .el-icon-video-play {
      font-size: 34px;
      color: #aaa;
      line-height: 68px;
    }
  }
  .mark:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  .mark:hover .el-icon-video-play {
    color: #fff;
  }
}
/deep/.el-table td,
.el-table th {
  padding: 7px 0 5px 0;
}
</style>