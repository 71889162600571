var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uploadImgDialog"},[_c('p',[_c('fileUpload',{ref:"uploadComponent",attrs:{"accept":_vm.accept,"multiple":true,"limit":_vm.options.limit,"limitSize":_vm.options.limitSize,"companyId":_vm.options.company_id,"addwaterMark":_vm.options.type == 'vividImg' || _vm.options.type == 'qualification'
          ? true
          : false},on:{"selectFile":_vm.selectFile,"uploadCompleteOSS":_vm.uploadCompleteOss,"uploadCompleteVOD":_vm.uploadCompleteVOD,"uploadProgressVOD":_vm.uploadProgressVOD}},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-plus","type":"primary"}},[(_vm.options.type == 'video')?_c('span',[_vm._v(_vm._s(_vm.$t("addVideo")))]):(
            _vm.options.type == 'inquiryFile' || _vm.options.type == 'boothFile'
          )?_c('span',[_vm._v(_vm._s(_vm.$t("addFile")))]):_c('span',[_vm._v(_vm._s(_vm.$t("addImage")))])])],1)],1),_c('div',{staticClass:"list-table"},[_c('el-table',{ref:"listTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.fileList,"height":"254","empty-text":_vm.emptyText}},[(_vm.options.type == 'video')?_c('el-table-column',{attrs:{"prop":"videoName","label":_vm.$t('videoName'),"width":"248"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.progress)?_c('div',[_c('el-progress',{attrs:{"text-inside":true,"stroke-width":24,"percentage":scope.row.progress}})],1):_c('div',{staticClass:"textOverflow",attrs:{"title":_vm._f("getFilesName")(scope.row.name)}},[_vm._v(" "+_vm._s(_vm._f("getFilesName")(scope.row.name))+" ")])]}}],null,false,3989102042)}):(
          _vm.options.type == 'inquiryFile' || _vm.options.type == 'boothFile'
        )?_c('el-table-column',{attrs:{"prop":"imgName","label":_vm.$t('fileName'),"width":"248"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"textOverflow",attrs:{"title":_vm._f("getFilesName")(scope.row.name)}},[_vm._v(" "+_vm._s(_vm._f("getFilesName")(scope.row.name))+" ")])]}}])}):_c('el-table-column',{attrs:{"prop":"imgName","label":_vm.$t('imageName'),"width":"248"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"textOverflow",attrs:{"title":_vm._f("getFilesName")(scope.row.name)}},[_vm._v(" "+_vm._s(_vm._f("getFilesName")(scope.row.name))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"format","label":_vm.$t('format'),"width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(_vm._s(_vm._f("getFileType")(scope.row.name)))])]}}])}),_c('el-table-column',{attrs:{"prop":"size","label":_vm.$t('size'),"width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(_vm._s(_vm._f("getFilesSize")(scope.row.size)))])]}}])}),_c('el-table-column',{attrs:{"prop":"handle","label":_vm.$t('handle'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteItem(scope)}}},[_vm._v(_vm._s(_vm.$t("delete")))])]}}])})],1)],1),_c('div',{staticClass:"dialog-footer"},[_c('el-button',{attrs:{"type":"default","size":"small"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('el-button',{attrs:{"type":"primary","size":"small","disabled":_vm.fileList.length == 0,"loading":_vm.btnLoading},on:{"click":_vm.handleUpload}},[_vm._v(_vm._s(_vm.$t("beginUpload")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }