<template>
  <div class="uploadImgDialog">
    <p>
      <fileUpload
        :accept="accept"
        :multiple="true"
        :limit="options.limit"
        :limitSize="options.limitSize"
        :companyId="options.company_id"
        @selectFile="selectFile"
        ref="uploadComponent"
        @uploadCompleteOSS="uploadCompleteOss"
        @uploadCompleteVOD="uploadCompleteVOD"
        @uploadProgressVOD="uploadProgressVOD"
        :addwaterMark="
          options.type == 'vividImg' || options.type == 'qualification'
            ? true
            : false
        "
      >
        <el-button size="small" icon="el-icon-plus" type="primary">
          <span v-if="options.type == 'video'">{{ $t("addVideo") }}</span>
          <span
            v-else-if="
              options.type == 'inquiryFile' || options.type == 'boothFile'
            "
            >{{ $t("addFile") }}</span
          >
          <span v-else>{{ $t("addImage") }}</span>
        </el-button>
      </fileUpload>
    </p>
    <div class="list-table">
      <el-table
        :data="fileList"
        style="width: 100%"
        height="254"
        :empty-text="emptyText"
        ref="listTable"
      >
        <el-table-column
          prop="videoName"
          :label="$t('videoName')"
          width="248"
          v-if="options.type == 'video'"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.progress">
              <el-progress
                :text-inside="true"
                :stroke-width="24"
                :percentage="scope.row.progress"
              ></el-progress>
            </div>

            <div
              class="textOverflow"
              :title="scope.row.name | getFilesName"
              v-else
            >
              {{ scope.row.name | getFilesName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="imgName"
          :label="$t('fileName')"
          width="248"
          v-else-if="
            options.type == 'inquiryFile' || options.type == 'boothFile'
          "
        >
          <template slot-scope="scope">
            <div class="textOverflow" :title="scope.row.name | getFilesName">
              {{ scope.row.name | getFilesName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="imgName"
          :label="$t('imageName')"
          width="248"
          v-else
        >
          <template slot-scope="scope">
            <div class="textOverflow" :title="scope.row.name | getFilesName">
              {{ scope.row.name | getFilesName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="format" :label="$t('format')" width="140">
          <template slot-scope="scope">
            <div>{{ scope.row.name | getFileType }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="size" :label="$t('size')" width="140">
          <template slot-scope="scope">
            <div>{{ scope.row.size | getFilesSize }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="handle" :label="$t('handle')" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteItem(scope)">{{
              $t("delete")
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="dialog-footer">
      <el-button type="default" size="small" @click="cancel">{{
        $t("cancel")
      }}</el-button>
      <el-button
        type="primary"
        size="small"
        @click="handleUpload"
        :disabled="fileList.length == 0"
        :loading="btnLoading"
        >{{ $t("beginUpload") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { MD5 } from "~/baseUtils/MD5";
import fileUpload from "./fileUpload";
import { getFileType, getFileName, getFileSize } from "~/baseUtils";
import { getenclosureType } from "~/basePlugins/filters";

export default {
  components: {
    fileUpload,
  },
  data() {
    return {
      fileList: [],
      urlList: [],
      btnLoading: false,
      accept: "*",
      emptyText: "",
      videoUploader: null,
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          limit: 5,
          company_id: null,
          booth_id: null,
          type: null, //vividImg  宣传图片,video 宣传视频,qualification 证书,inquiryFile 询盘附件 ,boothFile 展台资源
          limitSize: 0,
        };
      },
    },
  },
  created() {
    this.initacceptType();
  },
  mounted() {
    this.initCompanyId();
  },
  methods: {
    initCompanyId() {
      this.company_id = this.options.company_id
        ? this.options.company_id
        : this.$moment().format("MM-DD");
    },
    initacceptType() {
      if (this.options.type == "video") {
        this.accept = ".mp4, .avi, .rmvb, .ts, .flv, .wmv, video/*";
        this.emptyText = this.$t("pleaseAddVideo");
      } else if (
        this.options.type == "inquiryFile" ||
        this.options.type == "boothFile"
      ) {
        this.accept =
          ".jpg,.jpeg,.png,.bmp,.pdf,.doc,.docx,.xls,.xlsx,.rar,.zip";
        this.emptyText = this.$t("pleaseAddFile");
      } else {
        this.accept = ".jpg,.jpeg,.bmp,.png";
        this.emptyText = this.$t("pleaseAddImage");
      }
    },
    initUploader() {
      if (this.options.type == "video") {
        this.videoUploader = this.createVideoUploader();
      }
    },
    uploadCompleteOss(result) {
      let _fileList = this.fileList.concat([]);
      _fileList.forEach((item) => {
        let md5String = MD5(`${this.company_id}+${getFileName(item.name)}`);
        if (
          `${md5String}.${getFileType(item.name).toLowerCase()}` ==
          this.clearOssPath(result.name)
        ) {
          item.fileurl = result.url;
        }
      });
      let urlList = _fileList.filter((item) => {
        return item.fileurl;
      });
      console.log(urlList);
      if (urlList.length == _fileList.length) {
        this.urlList = urlList;
        this.saveInfotoService();
      }
    },
    uploadProgressVOD(result) {
      this.fileList.forEach((item) => {
        if (item.name == result.name) {
          item.progress = parseInt(result.progress);
        }
      });
      this.$refs.listTable.doLayout();
    },
    uploadCompleteVOD(result) {
      let _fileList = this.fileList.concat([]);
      _fileList.forEach((item) => {
        if (item.name == result.file.name) {
          item.videoId = result.videoId;
        }
      });
      let idsList = _fileList.filter((item) => {
        return item.videoId;
      });

      if (idsList.length == _fileList.length) {
        this.uploadServiceComplete();
      }
    },
    async saveInfotoService() {
      if (this.options.type == "vividImg") {
        this.createImages(this.urlList).then(async (images) => {
          let newImg = images.map((res) => {
            return res.url;
          });
          try {
            let result = await this.$store.dispatch(
              "baseStore/company_savePicture",
              {
                company_id: this.options.company_id,
                data_info: JSON.stringify(images),
              }
            );
            if (result.success) {
              this.uploadServiceComplete();
            }
          } catch (e) {}
        });
      }
      if (this.options.type == "qualification") {
        let params = {
          company_id: this.company_id,
          url: this.fileList
            .map((item) => {
              return item.fileurl;
            })
            .join(","),
        };
        try {
          let result = await this.$store.dispatch(
            "baseStore/company_uploadQualification",
            params
          );
          if (result.success) {
            this.uploadServiceComplete();
          }
        } catch (e) {}
      }
      if (this.options.type == "boothFile") {
        let list = this.urlList.map((item) => {
          return {
            name: item.name,
            url: item.fileurl,
            size: item.size,
          };
        });
        let params = {
          booth_id: this.options.booth_id,
          file_data: JSON.stringify(list),
        };
        try {
          let result = await this.$store.dispatch(
            "baseStore/addBoothData",
            params
          );
          if (result.success) {
            this.uploadServiceComplete();
          }
        } catch (e) {}
      }
      if (this.options.type == "inquiryFile") {
        let list = this.urlList.map((item) => {
          return {
            file_name: getFileName(item.name),
            url: item.fileurl,
            file_type: getenclosureType(getFileType(item.name)),
          };
        });

        this.uploadServiceComplete(list);
      }
    },
    createImages(files) {
      return new Promise((resolve, reject) => {
        let images = [];
        let dones = 0;
        files.forEach((item) => {
          let img = new Image();
          img.src = item.url;
          img.onload = function () {
            images.push({
              name: getFileName(item.name),
              format: getFileType(item.name),
              size: getFileSize(item.size),
              url: item.fileurl,
              width: img.width,
              height: img.height,
            });
            dones++;
            if (dones == files.length) {
              resolve(images);
            }
          };
        });
      });
    },
    clearOssPath(name) {
      let _name = name
        .replace("images/", "")
        .replace("userImages/", "")
        .replace("userFiles/", "");
      return _name;
    },
    uploadServiceComplete(list) {
      this.btnLoading = false;
      this.$refs.uploadComponent.clearFileList();
      this.fileList = [];
      this.urlList = [];
      if (list) {
        this.$emit("uploadSuccess", list);
      } else {
        this.$emit("uploadSuccess");
      }
    },
    cancel() {
      this.$refs.uploadComponent.clearFileList();
      this.fileList = [];
      this.$emit("closeDialog");
    },
    selectFile(filelist) {
      this.fileList = filelist;
    },
    deleteItem(scope) {
      if (this.btnLoading) {
        this.$message.warning(this.$t("uploadNotDeleteTip"));
        return;
      }
      this.fileList.splice(scope.$index, 1);
    },
    handleUpload() {
      this.btnLoading = true;
      if (this.options.type == "video") {
        this.$refs.uploadComponent.uploadVideoToVod();
      } else {
        this.$refs.uploadComponent.uploadFileToOss();
      }
    },
  },
};
</script>

<style scoped lang="less">
.uploadImgDialog {
  width: 630px;
  p {
    margin-bottom: 10px;
  }
  /deep/ .el-table td {
    padding: 0px 0px;
  }
}
/deep/ .el-table th {
  background: #fafafa;
}
</style>
